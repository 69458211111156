import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IObservation, IObservationDefinition } from "src/app/models/observations.interface";
import { IObservationParam, IObservationParamWithObsDefinition } from "src/app/models/patientConfig.interface";
import { IStreamGraphLine } from "src/app/models/stream-observation-interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class ObservationApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Observations", // Theme !
      ["dashboard/observationDef"], // Post route paths
      [
        "observations",
        "dashboard/observationsDef",
        "dashboard/observationsParam",
        "dashboard/observationsByQR",
        "dashboard/patientStreamObservationsLoincs",
        "dashboard/onlineDeviceData",
      ], // Get route paths
      [], // Update route paths
      [] // Delete route paths
    );
  }

  public list(patientId: string, fromDate?: string, toDate?: string, computeNorms?: boolean): Observable<IObservation[]> {
    return this.api.get(this.readRoutes[0], {
      identifier: patientId,
      fromDate,
      toDate,
      computeNorms,
    }) as Observable<IObservation[]>;
  }

  public listDef(caremateId?: string, loincs?: string[]): Observable<IObservationDefinition[]> {
    return this.api.get(this.readRoutes[1], { caremateId, observationsLoincs: loincs?.join(",") }) as Observable<IObservationDefinition[]>;
  }

  public listStream(patientId: string, loincs?: string[]): Observable<string[]> {
    return this.api.get(this.readRoutes[4], { patientId, loincs: loincs?.join(",") }) as Observable<string[]>;
  }

  public listParams(includeObsDef?: boolean): Observable<IObservationParamWithObsDefinition[]> {
    return this.api.get(this.readRoutes[2], { includeObsDef }) as Observable<IObservationParamWithObsDefinition[]>;
  }

  public listOnlineDeviceData(
    patientId: string,
    startDate: string,
    endDate: string,
    externalRessourceRef: string,
    loincs?: string,
    componentLoincs?: string[]
  ): Observable<IStreamGraphLine[]> {
    return this.api.get(this.readRoutes[5], { patientId, startDate, endDate, externalRessourceRef, loincs, componentLoincs }) as Observable<
      IStreamGraphLine[]
    >;
  }

  /**
   * Creates a new observation definition and parameter
   * @param obsDef The new observation definition to save as an ObservationDefinition
   * @param obsParam The new observation parameter to save as an IObservationParam
   * @returns The saved observation definition
   */
  public createObservationDef(obsDef: IObservationDefinition, obsParam: IObservationParam): Observable<IObservationDefinition> {
    const body = {
      observationDef: obsDef,
      observationParam: obsParam,
    };
    return this.api.post(this.createRoutes[0], body) as Observable<IObservationDefinition>;
  }

  /**
   * Returns an observation list (IObservation) from a linked questionnaireResponse identifier
   * @param questionnaireResponseRef
   * @returns
   */
  public getObservationsByQR(questionnaireResponseRef: string): Observable<IObservation[]> {
    return this.api.get(this.readRoutes[3], {
      questionnaireResponseRef,
    }) as Observable<IObservation[]>;
  }
}
