<div class="legend-container mt-1" [style.paddingLeft]="margin + 'px'" [style.paddingRight]="margin + 'px'">
  <div *ngFor="let legend of legends" class="legend-element">
    <div class="legend-icon" [ngStyle]="{ backgroundColor: legend.color }"></div>
    <span>{{ legend.date | date : "dd-MM-YYYY" }}</span>
  </div>
</div>

<div #chartContainer id="chart">
  <!-- Id must start with a letter, we arbittrary chose "A" -->
  <figure [id]="chartId" class="m-0" style="text-align: center"></figure>
</div>
