import * as d3 from "d3";

export enum xAxisType {
  XXIVHours = "24h",
  dates = "dates",
}

export enum yAxisType {
  percentile = "percentile",
  value = "value",
}

export class ChartHelper {
  /**
   * Creates an SVG element for the chart with the specified dimensions and margins.
   *
   * @param width - The width of the SVG element.
   * @param height - The height of the SVG element.
   * @param margin - The margin around the SVG element.
   * @param selector - The selector for the HTML element where the SVG will be appended.
   * @returns The created SVG element as a D3 selection.
   */
  public static createSvg(
    width: number,
    height: number,
    margin: number,
    selector: string
  ): d3.Selection<SVGGElement, unknown, HTMLElement, any> {
    const svg = d3
      .select(selector)
      .append("svg")
      .attr("width", width + margin * 2)
      .attr("height", height + margin * 2)
      .append("g")
      .attr("transform", "translate(" + margin + "," + margin + ")");
    return svg;
  }

  /**
   * Parse a D3 locale object from JSON format to the expected format for d3.timeFormatDefaultLocale().
   * @param d3Locale - The D3 locale object in JSON format from i18n file.
   * @returns The parsed D3 locale object in the expected format for d3.timeFormatDefaultLocale().
   */
  public static parseLocale(d3Locale: JSON): d3.TimeLocaleDefinition {
    const local = {};
    for (const [key, value] of Object.entries(d3Locale)) {
      local[key] = JSON.parse(value as string);
    }
    return local as d3.TimeLocaleDefinition;
  }
}
