import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { GetGenderDisplayPipe } from "../components/ans-practitionner-result/ans-practitionner-result.component";
import { IsActivityADrugPipe } from "../components/careplan/careplan.component";
import { getIdentityVerificationMethodDisplay } from "../components/forms/add-patient/add-patient.component";
import { FirstnamesToArrayPipe } from "../components/forms/add-patient/firstnames-to-array.pipe";
import { MapChartColorsPipe } from "../components/observation-chart/map-chart-colors.pipe";
import {
  IsQuestionEnabledPipe,
  QuestionNumberValuePipe,
  QuestionTextValuePipe,
} from "../components/patient-QR/answer-new-qr/answer-new-qr.component";
import { CanUpdatePipe, FindPreviousQRPipe, QRDatePipe, QRNamePipe } from "../components/patient-QR/patient-QR.component";
import {
  QuestionAnswerCodePipe,
  QuestionAnswerStrPipe,
  ValueSetChoicesPipe,
} from "../components/patient-QR/qr-details/qr-details.component";
import {
  FrequencyLabelPipe,
  HasDrugInfoLinkPipe,
  HasDrugKnowledgePipe,
  HasIntakesDataPipe,
  IntakesGaugePipe,
  IntakesToolTipPipe,
} from "../components/patient-drugs/patient-drugs.component";
import { GetCurrentObservationPipe } from "../components/patient-observations/moment-observation-table/moment-observation-table.component";
import { IsAdditionalOptionVisiblePipe } from "../components/timing-editor/timing-editor.component";
import { ValueObservationPipe } from "../models/observations.model";
import { ComputeScoringPipe } from "../models/questionnaireScoring.model";
import { FindGroupAccessPipe } from "../pages/access-groups-page/practitioner-access-groups/practitioner-access-groups.component";
import { GetDataSource, IsBasedOnCareplanPipe } from "../pages/communications-page/communications-page.component";
import { GetCanDeleteQuestion } from "../pages/questionnaire-editor-page/components/item-drag-drop/item-drag-drop.component";
import {
  GetCanBeInFormula,
  GetQuestionNumber,
  GetQuestionOptions,
  GetValueSetValue,
} from "../pages/questionnaire-editor-page/components/questionnaire-properties/questionnaire-scoring-formula/questionnaire-scoring-formula.component";
import { ValidDate } from "../pages/user-list-page/user-list-page.component";
import { ActivityDate } from "./activity-date.pipe";
import { ActivityLinkedToQuestionnaire } from "./activityLinkedToQuestionnaire.pipe";
import { AlertLevelClassPipe } from "./alert-level-class.pipe";
import { DateArrayPipe } from "./date-array.pipe";
import { FilterObsDefinitionsPipe } from "./filter-obs-definitions.pipe";
import { FilterObservationsByLoincPipe } from "./filter-observations-by-loinc.pipe";
import { FindObsDefByLoincPipe } from "./find-obs-def-by-loinc.pipe";
import { FindObsDefComponentsByLoincPipe } from "./find-obs-def-components-by-loinc.pipe";
import { FirstImagePipe } from "./first-image.pipe";
import { GetEffectiveTimingPipe } from "./get-effective-timing.pipe";
import { GetObsValuesByHoursPipe } from "./get-obs-values-by-hours.pipe";
import { GetPatientFullNamePipe } from "./get-patient-full-name.pipe";
import { GetShortnamePipe } from "./get-shortname.pipe";
import { GetLatestIdentityFromPatientPipe } from "./getLatestIdentityFromPatient.pipe";
import { IsAuthorizedPipe } from "./isAuthorized.pipe";
import { IsGoodAnswerPipe, IsGoodAnswerVer2Pipe } from "./isGoodAnswer.pipe";
import { JoinPipe } from "./join.pipe";
import { LogPipe } from "./log.pipe";
import { GetTranslationPipe, LoincAndValueMeaningPipe } from "./loinc-to-itranslation.pipe";
import { EnumToArrayPipe } from "./number-enum-to-array.pipe";
import {
  GetLabelRangeFromValuePipe,
  GetObsComponentPipe,
  GetRangePipe,
  IsObsCompEnabledPipe,
  RangeSmileyPipe,
  TranslateObservationNameFromObsDefPipe,
} from "./observation.pipe";
import { GetOrgNameFromServiceRefPipe, OrgNameFromServiceRefAndMonitLinksPipe } from "./orgNameFromServiceRef.pipe";
import { PositionStylePipe } from "./position-style.pipe";
import { RaHasPdfPipe } from "./ra-has-pdf.pipe";
import { RiskGaugePipe } from "./risk-gauge.pipe";
import { SanitizeHtmlPipe } from "./sanitize-html.pipe";
import { TranslateAlertNamePipe } from "./sharedAlerts.pipe";
import { SplitPipe } from "./split.pipe";
import { GetClassesForStepwisePipe, GetStepwisePipe, IsLastOrFirstCellOfStepwisePipe, IsStepwiseSelectedPipe } from "./stepwise-drug.pipe";

import { FilterByKeyValuePipe } from "./filter-by-key-value.pipe";
import { TruncateListPipe } from "./truncate-list.pipe";
import { TruncatePipe } from "./truncate.pipe";
import { TypeOfPipe } from "./typeof.pipe";

@NgModule({
  declarations: [
    IsActivityADrugPipe,
    IsAdditionalOptionVisiblePipe,
    CanUpdatePipe,
    ComputeScoringPipe,
    FindPreviousQRPipe,
    LoincAndValueMeaningPipe,
    GetTranslationPipe,
    ValueObservationPipe,
    QuestionAnswerStrPipe,
    ValueSetChoicesPipe,
    QuestionAnswerCodePipe,
    IsQuestionEnabledPipe,
    QuestionTextValuePipe,
    QuestionNumberValuePipe,
    TranslateAlertNamePipe,
    QRNamePipe,
    QRDatePipe,
    GetEffectiveTimingPipe,
    ActivityDate,
    FirstImagePipe,
    SanitizeHtmlPipe,
    ValidDate,
    EnumToArrayPipe,
    IsGoodAnswerPipe,
    IsGoodAnswerVer2Pipe,
    IsBasedOnCareplanPipe,
    GetDataSource,
    RaHasPdfPipe,
    TranslateObservationNameFromObsDefPipe,
    OrgNameFromServiceRefAndMonitLinksPipe,
    SplitPipe,
    JoinPipe,
    GetCurrentObservationPipe,
    IntakesGaugePipe,
    HasIntakesDataPipe,
    IntakesToolTipPipe,
    FrequencyLabelPipe,
    TypeOfPipe,
    FindGroupAccessPipe,
    IsAuthorizedPipe,
    RiskGaugePipe,
    ActivityLinkedToQuestionnaire,
    GetGenderDisplayPipe,
    getIdentityVerificationMethodDisplay,
    GetLatestIdentityFromPatientPipe,
    GetPatientFullNamePipe,
    FilterObsDefinitionsPipe,
    FilterObservationsByLoincPipe,
    GetObsValuesByHoursPipe,
    GetShortnamePipe,
    FindObsDefComponentsByLoincPipe,
    LogPipe,
    IsStepwiseSelectedPipe,
    PositionStylePipe,
    GetClassesForStepwisePipe,
    IsLastOrFirstCellOfStepwisePipe,
    GetStepwisePipe,
    FirstnamesToArrayPipe,
    TruncatePipe,
    AlertLevelClassPipe,
    FindObsDefByLoincPipe,
    MapChartColorsPipe,
    GetCanDeleteQuestion,
    GetQuestionOptions,
    GetCanBeInFormula,
    GetQuestionNumber,
    DateArrayPipe,
    GetOrgNameFromServiceRefPipe,
    GetRangePipe,
    RangeSmileyPipe,
    GetLabelRangeFromValuePipe,
    IsObsCompEnabledPipe,
    TruncateListPipe,
    GetValueSetValue,
    HasDrugKnowledgePipe,
    HasDrugInfoLinkPipe,
    GetObsComponentPipe,
    FilterByKeyValuePipe,
  ],
  imports: [CommonModule],
  exports: [
    IsAdditionalOptionVisiblePipe,
    CanUpdatePipe,
    ComputeScoringPipe,
    FindPreviousQRPipe,
    LoincAndValueMeaningPipe,
    GetTranslationPipe,
    ValueObservationPipe,
    QuestionAnswerStrPipe,
    ValueSetChoicesPipe,
    QuestionAnswerCodePipe,
    IsQuestionEnabledPipe,
    QuestionTextValuePipe,
    QuestionNumberValuePipe,
    TranslateAlertNamePipe,
    QRNamePipe,
    QRDatePipe,
    GetEffectiveTimingPipe,
    ActivityDate,
    FirstImagePipe,
    SanitizeHtmlPipe,
    ValidDate,
    EnumToArrayPipe,
    IsGoodAnswerPipe,
    IsGoodAnswerVer2Pipe,
    IsBasedOnCareplanPipe,
    GetDataSource,
    RaHasPdfPipe,
    TranslateObservationNameFromObsDefPipe,
    OrgNameFromServiceRefAndMonitLinksPipe,
    SplitPipe,
    JoinPipe,
    GetCurrentObservationPipe,
    IntakesGaugePipe,
    IntakesToolTipPipe,
    HasIntakesDataPipe,
    FrequencyLabelPipe,
    IsActivityADrugPipe,
    TypeOfPipe,
    FindGroupAccessPipe,
    IsAuthorizedPipe,
    RiskGaugePipe,
    ActivityLinkedToQuestionnaire,
    GetGenderDisplayPipe,
    getIdentityVerificationMethodDisplay,
    GetLatestIdentityFromPatientPipe,
    GetPatientFullNamePipe,
    FilterObsDefinitionsPipe,
    FilterObservationsByLoincPipe,
    GetObsValuesByHoursPipe,
    GetShortnamePipe,
    FindObsDefComponentsByLoincPipe,
    LogPipe,
    IsStepwiseSelectedPipe,
    PositionStylePipe,
    GetClassesForStepwisePipe,
    IsLastOrFirstCellOfStepwisePipe,
    GetStepwisePipe,
    FirstnamesToArrayPipe,
    TruncatePipe,
    AlertLevelClassPipe,
    FindObsDefByLoincPipe,
    MapChartColorsPipe,
    GetCanDeleteQuestion,
    GetQuestionOptions,
    GetCanBeInFormula,
    GetQuestionNumber,
    DateArrayPipe,
    GetOrgNameFromServiceRefPipe,
    GetRangePipe,
    RangeSmileyPipe,
    GetLabelRangeFromValuePipe,
    IsObsCompEnabledPipe,
    TruncateListPipe,
    GetValueSetValue,
    HasDrugKnowledgePipe,
    HasDrugInfoLinkPipe,
    GetObsComponentPipe,
    FilterByKeyValuePipe,
  ],
  providers: [IsStepwiseSelectedPipe, GetStepwisePipe], // add pipe to providers to use them inside component.ts e.g. this.myPipe.transform(value)
})
export class PipesModule {}
