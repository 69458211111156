import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from "@angular/core";
import moment from "moment";
import { xAxisType, yAxisType } from "src/app/helpers/chart-helper";
import { IChartDataPoint } from "src/app/models/sharedInterfaces";
import { EXTERNAL_RESSOURCE_REF, IChartConfig, IStreamGraphLine } from "src/app/models/stream-observation-interface";
import { ObservationsService } from "src/app/providers/observations.service";
import { BaseChartComponent } from "../base-chart/base-chart.component";

@Component({
  selector: "app-stream-obs-chart",
  templateUrl: "./stream-obs-chart.component.html",
  styleUrls: ["./stream-obs-chart.component.scss", "../base-chart/base-chart.component.scss"],
})
export class StreamObsChartComponent extends BaseChartComponent implements AfterViewInit, OnChanges {
  @Input() private fromDate: moment.Moment;
  @Input() private toDate: moment.Moment;
  @Input() private patientId: string;
  @Input() loinc: string;

  @ViewChild("chartContainer") chartContainer: ElementRef<HTMLDivElement>;

  private colorPalette = ["#FF5733", "#33C1FF", "#FF33A8", "#6ABF4B", "#FFB533", "#8D33FF", "#FF3358"];

  protected chartConfig: IChartConfig = {
    margin: 30,
    width: 800,
    height: 400,
    xAxis: { type: xAxisType.XXIVHours },
    yAxis: { type: yAxisType.value, min: 0, max: 400 },
  };

  public data: IStreamGraphLine[];
  public legends: { color: string; date: moment.Moment }[] = [];
  public component;

  constructor(private observationsService: ObservationsService, private elementRef: ElementRef) {
    super();
    this.component = elementRef;
  }

  async ngAfterViewInit(): Promise<void> {
    await super.createChart();
  }

  async ngOnChanges(): Promise<void> {
    // If one or several input change, we need to refresh the data
    this.data = await this.observationsService
      .listOnlineDeviceData(
        this.patientId,
        this.fromDate?.format("YYYY-MM-DD"),
        this.toDate?.format("YYYY-MM-DD"),
        EXTERNAL_RESSOURCE_REF.dexcomGen7,
        this.loinc
      )
      .toPromise();

    this.drawPaths();
  }

  private drawPaths() {
    this.legends = [];
    // Remove all old circles before drawing new ones
    this.svg?.selectAll("circle")?.remove();

    for (let index = 0; index < this.data.length; index++) {
      const d = this.data[index];

      const dataPoints: IChartDataPoint[] = d.values.map((el) => ({
        value: el.valueQuantity,
        minutesAfterMidnight: el.minutesAfterMidnight,
      }));

      this.legends.push({ color: this.colorPalette[index], date: moment(this.data[index].dateOfDay) });

      const circles = this.svg
        .selectAll("myCircles")
        .data(dataPoints)
        .enter()
        .append("circle")
        .attr("fill", this.colorPalette[index])
        .attr("stroke", "none")
        .attr("cx", (d) => {
          return this.x(d.minutesAfterMidnight);
        })
        .attr("cy", (d) => {
          return this.y(d.value);
        })
        .attr("r", 2)
        .attr("data-color", this.colorPalette[index]);
      circles.raise();
    }

    this.legends.sort((a, b) => +a.date - +b.date); // + are used to cast moment in number
  }
}
