import { Component, OnDestroy, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { WidgetItem } from "src/app/components/widgets/widget-item";
import { PreferenceContext, WidgetParameter } from "src/app/models/preference.interface";
import { ModalManagementService } from "src/app/providers/modal-management.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { SessionService } from "src/app/providers/session.service";
import { WidgetManagerService } from "src/app/providers/widget-manager.service";
import { LoginPageComponent } from "../login-page/login-page.component";

@Component({
  selector: "app-dashboard-page",
  templateUrl: "./dashboard-page.component.html",
  styleUrls: ["./dashboard-page.component.scss"],
})
export class DashboardPageComponent implements OnDestroy, OnInit {
  public widgetParameter: WidgetParameter;
  public widgetItems: WidgetItem[];
  public nbColumn = 3;
  private isWidgetMoved = false;

  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  constructor(
    private widgetManagerService: WidgetManagerService,
    private preferenceService: PreferenceService,
    private sessionService: SessionService,
    private cookieService: CookieService,
    private modalManagementService: ModalManagementService
  ) {
    this.loadPreference();
  }

  ngOnInit(): void {
    this.sessionService.refreshDashboardPage.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.loadPreference();
      // avoid bug when dragging tile after updating preference; see CMATE-3694 + CMATE-4181
      if (this.isWidgetMoved) {
        window.location.reload();
      }
    });

    if (this.cookieService.check(LoginPageComponent.COOKIE_REDIRECT_MODALID)) {
      this.modalManagementService
        .redirectToGivenModal(
          this.cookieService.get(LoginPageComponent.COOKIE_REDIRECT_MODALID),
          this.cookieService.get(LoginPageComponent.COOKIE_REDIRECT_SOURCE)
        )
        .then(() => {
          this.cookieService.delete(LoginPageComponent.COOKIE_REDIRECT_MODALID);
          this.cookieService.delete(LoginPageComponent.COOKIE_REDIRECT_SOURCE);
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public widgetMoved(widgets: WidgetItem[]): void {
    this.isWidgetMoved = true;
    this.preferenceService
      .update({
        context: PreferenceContext.WIDGET,
        parameters: { ...this.widgetParameter, widgets },
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  private loadPreference() {
    this.preferenceService
      .list(PreferenceContext.WIDGET)
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe(
        (widgetParameter: WidgetParameter) => {
          if (widgetParameter) {
            this.widgetParameter = widgetParameter;
          } else {
            this.widgetParameter = this.widgetManagerService.defaultParameter;
          }
        },
        () => {
          this.widgetParameter = this.widgetManagerService.defaultParameter;
          this.nbColumn = this.widgetParameter.nbColumn;
        },
        () => {
          this.widgetItems = this.widgetManagerService.getWidgetItems(this.widgetParameter.widgets);
          this.nbColumn = this.widgetParameter.nbColumn;
        }
      );
  }
}
