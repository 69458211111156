import { Component, ElementRef, ViewChild } from "@angular/core";
import * as d3 from "d3";
import { ChartHelper, xAxisType, yAxisType } from "src/app/helpers/chart-helper";
import { IChartConfig } from "src/app/models/stream-observation-interface";
import uuid from "uuid-random";

@Component({
  selector: "app-base-chart",
  templateUrl: "./base-chart.component.html",
  styleUrls: ["./base-chart.component.scss"],
})
export abstract class BaseChartComponent {
  protected abstract chartConfig: IChartConfig;

  public chartId = "a" + uuid().slice(1);

  @ViewChild("chartContainer") chartContainer: ElementRef<HTMLDivElement>;
  public svg: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>;

  // SVG dimensions
  public margin: number;
  private width: number;
  private height: number;

  public x: d3.ScaleLinear<number, number, never> | d3.ScaleTime<number, number, never>;
  public y: d3.ScaleLinear<number, number, never>;

  private applyConfig() {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        this.margin = this.chartConfig.margin;
        this.width = this.chartConfig.width - this.margin * 2;
        this.height = this.chartConfig.height - this.margin * 2;
        resolve();
      });
    });
  }
  protected async createChart(): Promise<void> {
    // We make sure to remove the existing chart in case it already exists
    d3.select(`figure#${this.chartId}`).selectAll("*").remove();
    await this.applyConfig();
    this.createSvg();
    const { x, y } = await this.createAxis();
    this.x = x;
    this.y = y;
  }

  private createSvg() {
    this.svg = ChartHelper.createSvg(this.width, this.height, this.margin, `figure#${this.chartId}`); // we add "A" because a css selector can't start with a number
  }

  private async createAxis() {
    return { x: this.createXAxis(), y: this.createYAxis() };
  }

  private createXAxis() {
    // Define x-axis using linear scale for minutesSinceMidnight

    switch (this.chartConfig.xAxis.type) {
      case xAxisType.XXIVHours: {
        const x = d3
          .scaleLinear()
          .domain([0, 1440]) // 0 minutes to 1440 minutes (24 hours)
          .range([this.margin, this.width - this.margin]);

        // Append x axis to SVG
        this.svg
          .append("g")
          .attr("transform", `translate(0,${this.height - this.margin})`)
          .call(
            d3
              .axisBottom(x)
              .tickValues(d3.range(0, 1441, 180)) // Set ticks every 180 minutes (3 hours)
              .tickFormat((d: number) => {
                const hours = Math.floor(d / 60);
                const minutes = d % 60;
                return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
              }) // Format as HH:MM
          );

        return x;
      }

      case xAxisType.dates: {
        const x = d3
          .scaleUtc()
          .domain([this.chartConfig.xAxis.min, this.chartConfig.xAxis.max])
          .range([this.margin, this.width - this.margin]);

        this.svg
          .append("g")
          .attr("transform", `translate(0,${this.height - this.margin})`)
          .call(
            d3
              .axisBottom(x)
              .ticks(this.width / 80)
              .tickSizeOuter(0)
          );
        return x;
      }
    }
  }

  private createYAxis() {
    switch (this.chartConfig.yAxis.type) {
      case yAxisType.percentile: {
        const y = d3
          .scaleLinear()
          .domain([this.chartConfig.yAxis.min, this.chartConfig.yAxis.max])
          .range([this.height - this.margin, this.margin]);

        // append y axis to svg
        this.svg
          .append("g")
          .attr("transform", `translate(${this.margin},0)`)
          .call(d3.axisLeft(y).ticks(this.height / 40))
          .call((g) => g.select(".domain").remove())
          .call((g) =>
            g
              .selectAll(".tick line")
              .clone()
              .attr("x2", this.width - this.margin * 2)
              .attr("stroke-opacity", 0.1)
          );

        return y;
      }

      case yAxisType.value: {
        const y = d3
          .scaleLinear()
          .domain([this.chartConfig.yAxis.min, this.chartConfig.yAxis.max])
          .range([this.height - this.margin, this.margin]);

        // append y axis to svg
        this.svg
          .append("g")
          .attr("transform", `translate(${this.margin},0)`)
          .call(d3.axisLeft(y).ticks(this.height / 40))
          .call((g) => g.select(".domain").remove())
          .call((g) =>
            g
              .selectAll(".tick line")
              .clone()
              .attr("x2", this.width - this.margin * 2)
              .attr("stroke-opacity", 0.1)
          );

        return y;
      }
    }
  }
}
